import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));

export class PrivacyPolicy extends Component {

    render() {

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/privacy-policy/"
                        title="Softtik Technologies - Privacy Policy"
                        description="Privacy Policy is a detailed description of what information we collect from users. Furthermore, it describes how Softtik can use this collected information.   "
                    />
                    <main onWheel={this.onScroll}>
                        <div id="wrapper">
                            <div className="collection-page privacy-policy-page">
                                {/* Banner */}
                                <section className="banner-sec">
                                    <div className="banner-content">
                                        <div className="auto-container">
                                            <div className='row'>
                                                <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 ">
                                                    <div className='banner-text wow fadeIn' data-wow-delay="0.3s">
                                                        <h1>Privacy policy </h1>
                                                        <p>This policy (with our terms of service and any other documents referred to) explains how we will process and utilize any personal data we acquire from you or that you provide. Please read the following instructions carefully to understand our perspectives and practices regarding your personal data and how we will treat it. You acknowledge and consent to the procedures outlined in this policy by visiting www.softtik.com.</p>
                                                        <p>We may gather and use the following information about you:</p>
                                                        <h4>Information you share with us.</h4>
                                                        <p>You may provide information to us about yourself by filling out forms on our or communicating with us via phone, e-mail, or otherwise. This includes information you give when you register to use our site, subscribe to our service, participate in our site's discussion boards or other social media services, or report a problem with our site. We may collect your name, address, e-mail address, phone number, bank and credit card information, personal description, and photograph.</p>

                                                        <h4>Information we collect about you.</h4>
                                                        <p>When visiting our website, we may collect the following information about you:</p>
                                                        <ul className="list-about style-two">
                                                            <li>We may collect technical data, such as the IP address, login credentials, information about the browser, time zone setting, browser plug-in versions and types, operating system, and platform.</li>
                                                            <li>We might gather information about services and products you searched or viewed, the response time of pages, download errors, the number of visits to certain pages, and web page interaction data such as clicks, searching, and mouse-overs on that page. We also might store the phone number used to contact the softtik Technologies team and the method utilized to browse away from certain pages. </li>
                                                        </ul>


                                                        <h4>Information we receive from third parties.</h4>
                                                        <ul className="list-about style-two">
                                                            <li>We obtain information from several different sources. We may receive and utilize information about you if you view or search any other websites or services we operate. [In this scenario, we will have warned you when we gathered the data that it might be shared internally and stored with data collected on this site.] </li>
                                                            <li>We also collaborate closely with third parties (for example, commercial partners, technical, payment, and delivery service subcontractors, advertising networks, analytics providers, search information providers, and credit reference agencies). We may get information about you from them.</li>
                                                        </ul>


                                                        <h2>Cookies</h2>
                                                        <p>Softtik Technologies website may use cookies to offer a unique user experience and produce overall website visitor statistics. Cookies can’t be used to find the identity of someone unless they tell us who they are. You always have the choice to disable cookies from your browser’s options menu or preference. </p>

                                                        <h4>Disclosure of User Information</h4>
                                                        <p>Softtik Technologies will not share, rent, or sell your personal data with any companies or other non-affiliated parties. Suppose you have shared your data through an email or any other source. In that case, Softtik.com maintains strict security and ensures that such information is only accessible to the person responsible for responding to your requests directly or indirectly. </p>

                                                        <h4>We might Disclose Information in the Following Situations.</h4>
                                                        <ul className="list-about style-two">
                                                            <li>Under a strict confidentiality agreement, we might offer your information to our trusted partners who work with Softtik Technologies or on our behalf. </li>
                                                            <li>We are bound to respond to court orders, subpoenas, and legal procedures and defend against or establish legal claims. </li>
                                                            <li>Softtik Technologies firmly believes that it is necessary to share information for investigating frauds and illegal activities or to prevent any person from physical harm. </li>
                                                        </ul>

                                                        <h4>Links to Other Sites</h4>
                                                        <p>Our website may contain links to and from the websites of our partner networks, advertisers, and affiliates from time to time. If you click a link to any of these websites, please be aware that these websites have their own privacy policies, for which we accept no responsibility or obligation. Please review these rules before submitting any personal information to these websites.</p>
                                                        <p>You can exercise your right to request that we do not use your personal information for marketing purposes. If we intend to use your data for such purposes or if we want to disclose your information to any third party for such reasons, we will normally notify you (before collecting your data). By checking certain boxes on the forms we use to collect your data, you can exercise your right to prevent such processing. You can also exercise your right anytime by writing to us at info@softtik.com. </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default PrivacyPolicy;